<template>
  <div class="row m-3 shadow align-items-center border bg-light">
    <div class="col-sm-auto">
      <h5><i class="fas fa-file-archive"></i></h5>
    </div>

    <div class="col-sm">
      <h5>{{ rar.name }}</h5>
    </div>

    <div class="col-sm">
      <h5>{{ rar.size }} Gb</h5>
    </div>

    <div class="col-sm">
      <input type="text" v-model="passwd" placeholder="Contraseña" class="form-control">
    </div>

    <div class="col-sm">
      <select v-model="destino">
        <option :value="config.descargas">Descargas</option>
        <option :value="config.descargasSeries">Descargas series</option>
      </select>
    </div>

    <div class="col-sm text-center">
      <LoadingButton @clicked="unrar" :iconProp="'fas fa-file-archive'" :loadingProp="loadingUnrar" :classProp="'btn btn-outline-info'" :textProp="'Descomprimir'"></LoadingButton>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '../LoadingButton.vue'
  import axios from 'axios'
  import config from '../../config'
  import Swal from 'sweetalert2'

  export default {
    props: ['rar'],
    data() {
      return {
        loadingUnrar: false,
        passwd: null,
        destino: null,
        config
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async unrar() {
        try {
          if (!this.destino) {
            return Swal.fire({
              title: 'Error',
              text: 'Tienes que elegir una carpeta de destino',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }

          this.loadingUnrar = true

          const res = await axios.post(config.apiUrl + '/descargas/unrar', {
            archivo: this.rar.path,
            destino: this.destino,
            passwd: this.passwd
          })

          this.loadingUnrar = false

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('rar-procesado')
        } catch (e) {
          this.loadingUnrar = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
