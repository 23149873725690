<template>
  <div class="row m-3 shadow align-items-center border bg-light text-center">
    <div class="col-sm-auto">
      <h5><i class="fas fa-film"></i></h5>
    </div>

    <div class="col-sm">
      <h5>{{ video.name }}</h5>
    </div>

    <div class="col-sm-auto">
      <h5>{{ video.size }} Gb</h5>
    </div>

    <div class="col-lg text-center">
      <Multiselect
        v-model="selectedOption"
        :close-on-select="true"
        :options="options"
        @search-change="searchTmdb"
        placeholder="Busca una película"
        :internal-search="false"
        :clear-on-select="true"
        :custom-label="nameWithYear"
      >
        <template
          slot="option"
          slot-scope="props"
        >
          <img
            class="option__image"
            :src="'https://image.tmdb.org/t/p/h100' + props.option.poster_path"
            :alt="props.option.title"
          >
          <span class="option__desc">
            <span class="option__title">
              {{ props.option.title }} ({{ props.option.release_date }})
            </span>
          </span>
        </template>
      </Multiselect>
    </div>

    <div class="col-sm-auto text-center">
      <p>Calidad: </p>
      <select v-model="calidad">
        <option value="" disabled selected>-</option>
        <option value="360p">360p</option>
        <option value="480p">480p</option>
        <option value="720p">720p</option>
        <option value="1080p">1080p</option>
        <option value="4K">4K</option>
      </select>
    </div>

    <div class="col-sm-auto text-center">
      <p>¿Seed?</p>
      <input type="checkbox" v-model="seed">
    </div>

    <div class="col-sm-auto text-center">
      <p>¿Poner titulo original?</p>
      <input type="checkbox" v-model="ingles">
    </div>

    <div class="col-sm-auto text-center">
      <LoadingButton @clicked="procesar" :iconProp="'fas fa-microchip'" :loadingProp="loadingProcesar" :classProp="'btn btn-outline-info'" :textProp="'Procesar'"></LoadingButton>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '../LoadingButton.vue'
  import Multiselect from 'vue-multiselect'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import config from '../../config'
  import _ from 'lodash'

  export default {
    props: ['video'],
    data() {
      return {
        loadingProcesar: false,
        calidad: null,
        ingles: false,
        seed: false,
        selectedOption: null,
        options: []
      }
    },
    components: {
      LoadingButton,
      Multiselect
    },
    methods: {
      async procesar() {
        try {
          if (!this.calidad) {
            return Swal.fire({
              title: 'Error',
              text: 'Tienes que elegir una calidad',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true
            })
          }

          this.loadingProcesar = true

          const res = await axios.post(config.apiUrl + '/descargas/procesar', {
            archivo: this.video.path,
            calidad: this.calidad,
            ingles: this.ingles,
            seed: this.seed,
            tmdbId: this.selectedOption.id
          })

          this.loadingProcesar = false

          await Swal.fire({
            title: 'Bien',
            text: res.data,
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })

          this.$emit('video-procesado')
        } catch (e) {
          this.loadingProcesar = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      },
      searchTmdb: _.debounce(async function(query) {
        try {

          if (!query) {
            return
          }

          const res = await axios.get('https://api.themoviedb.org/3/search/movie?language=es&api_key=34213f6650be159c39dfe30df7ecb001&query=' + query)

          this.options = res.data.results
        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: 'Ha habido un error buscando la película, inténtalo más tarde',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }, 200),
      nameWithYear(option) {
        return `${option.title} (${option.release_date})`
      },
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
