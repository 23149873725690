<template>
  <div>
    <p v-show="errorVideos">Error obteniendo los archivos de vídeo</p>

    <h3 v-show="loadingVideos" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!loadingVideos && !errorVideos">
      <div class="text-center">
        <p class="mt-3"><strong>{{ videos.length }}</strong> archivo/s de video en descargas</p>
      </div>

      <VideoFileInDescargas v-for="video in videos" :key="video.path" :video="video" @video-procesado="getVideos"></VideoFileInDescargas>
    </div>

    <p v-show="errorRars">Error obteniendo los archivos RAR</p>

    <h3 v-show="loadingRars" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!loadingRars && !errorRars">
      <div class="text-center">
        <p class="mt-3"><strong>{{ rars.length }}</strong> archivo/s RAR en descargas</p>
      </div>

      <RarFileInDescargas v-for="rar in rars" :key="rar.path" :rar="rar" @rar-procesado="getRars"></RarFileInDescargas>
    </div>

    <p v-show="errorTxts">Error obteniendo los archivos TXT</p>

    <h3 v-show="loadingRars" class="text-center"><i class="fas fa-circle-notch fa-spin"></i></h3>

    <div v-if="!loadingTxts && !errorTxts">
      <div class="text-center">
        <p class="mt-3"><strong>{{ txts.length }}</strong> archivo/s TXT en descargas</p>
      </div>

      <TxtFileInDescargas v-for="txt in txts" :key="txt.path" :txt="txt"></TxtFileInDescargas>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import config from '../../config'
  import VideoFileInDescargas from './VideoFileInDescargas'
  import RarFileInDescargas from './RarFileInDescargas'
  import TxtFileInDescargas from './TxtFileInDescargas'

  export default {
    data() {
      return {
        videos: [],
        loadingVideos: true,
        errorVideos: false,
        rars: [],
        loadingRars: true,
        errorRars: false,
        txts: [],
        loadingTxts: true,
        errorTxts: false,
      }
    },
    components: {
      VideoFileInDescargas,
      RarFileInDescargas,
      TxtFileInDescargas
    },
    methods: {
      async getVideos() {
        try {
          this.loadingVideos = true
          const res = await axios.get(config.apiUrl + '/descargas/videos?path=' + config.descargas)
          this.loadingVideos = false
          this.videos = res.data
        } catch (e) {
          this.loadingVideos = false
          this.errorVideos = true
        }
      },
      async getRars() {
        try {
          this.loadingRars = true
          const res = await axios.get(config.apiUrl + '/descargas/rars?path=' + config.descargas)
          this.loadingRars = false
          this.rars = res.data
        } catch (e) {
          this.loadingRars = false
          this.errorRars = true
        }
      },
      async getTxts() {
        try {
          this.loadingTxts = true
          const res = await axios.get(config.apiUrl + '/descargas/txts?path=' + config.descargas)
          this.loadingTxts = false
          this.txts = res.data
        } catch (e) {
          this.loadingTxts = false
          this.errorTxts = true
        }
      }
    },
    mounted() {
      this.$store.commit('setPanelLayout')
      this.getVideos()
      this.getRars()
      this.getTxts()
    }
  }
</script>
