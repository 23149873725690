<template>
  <div class="row m-3 shadow align-items-center border bg-light">
    <div class="col-sm-auto">
      <h5><i class="fas fa-file-archive"></i></h5>
    </div>

    <div class="col-sm">
      <h5>{{ txt.name }}</h5>
    </div>

    <div class="col-sm text-center">
      <LoadingButton @clicked="leer" :iconProp="'fas fa-file'" :loadingProp="loadingLeer" :classProp="'btn btn-outline-info'" :textProp="'Leer'"></LoadingButton>
    </div>
  </div>
</template>

<script>
  import LoadingButton from '../LoadingButton.vue'
  import axios from 'axios'
  import config from '../../config'
  import Swal from 'sweetalert2'

  export default {
    props: ['txt'],
    data() {
      return {
        loadingLeer: false,
      }
    },
    components: {
      LoadingButton
    },
    methods: {
      async leer() {
        try {
          this.loadingLeer = true

          const res = await axios.post(config.apiUrl + '/archivos/leer', {
            path: this.txt.path
          })

          this.loadingLeer = false

          Swal.fire({
            html: `
            <div class="text-left" style="white-space: pre-wrap;">
              ${res.data}
            </div>
            `,
          })
        } catch (e) {
          this.loadingLeer = false

          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
